import React, { Component } from "react"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"
import IndexHeader from "../components/header/index"
import Footer from "../components/footer"
import Card from "../components/card"
import Form from "../components/contactForm"
import ReviewSection from "../components/reviews/reviewSection"

import House from "../images/house.svg"
import Truck from "../images/truck.svg"
import Interior from "../images/interior-design.svg"
import Envelope from "../images/envelope-white.svg"
import Phone from "../images/phone-white.svg"

import "../components/layout.css"

export default class IndexPage extends Component {
  render() {
    const latestWorks = this.props.data.allContentfulPortfolioWork.nodes
    return (
      <div>
        <SEO title="Home" />
        <IndexHeader />
        <div className="bg-orange flex flex-wrap p-4 justify-center">
          <p className="my-1 pr-5 text-center">Consulting and estimates for your project, contact us today!</p>
          <Link
            className="shadow bg-grayDarkest hover:bg-grayDark focus:shadow-outline focus:outline-none py-1 px-3 rounded text-white"
            to="/contacts">
            Get a quote
          </Link>
        </div>

        <section className="flex flex-col md:flex-row bg-grayLight my-24 py-12 md:px-5 lg:px-16">
          <div className="flex flex-col items-center">
            <img className="h-24" src={House} alt="Services - Exteriors" />
            <h2 className="mt-0">Exteriors</h2>
            <p className="px-8">
              We can provide all services that
              you might need on the exterior
              of your home.  We do
              extensions, roofing work and
              loft conversions.
            </p>
          </div>
          <div className="flex flex-col items-center px-5 my-5 md:my-0 lg:px-20">
            <img className="h-24" src={Interior} alt="Services - Interiors" />
            <h2 className="mt-0">Interiors</h2>
            <p>
              We can also do anything you
              need inside your home. Be it in
              the kitchen, bathroom, rooms or
              halls.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img className="h-24" src={Truck} alt="Services - Other" />
            <h2 className="mt-0">Other Services</h2>
            <p className="px-8">
              Need something specific? There is
              a high chance we can help you!
              We also do gardens, garages and
              doors or windows refurbishments.
            </p>
          </div>
        </section>

        <section className="mt-24">
          <h1 className="relative orange-underline text-center mb-8">Latest Work</h1>
          <div className="flex flex-wrap sm:mx-5 md:mx-8 justify-center container">
            {latestWorks.map(work => (
              <Card key={work.slug} data={work} />
            ))}
          </div>
        </section>

        <ReviewSection />
        <section className="bg-grayDarkest flex flex-col md:flex-row justify-between px-5 lg:px-20">
          <div className="md:py-12 lg:px-24 font-thin md:w-full lg:w-3/6 text-white">
            <h1 className="relative orange-underline text-center text-white">Contacts</h1>
            <p className="mb-3">Want to know how we can help you? Need a quote or a
            consulting for your project? You can contact us by either
            filling the form, sending an email or giving us a call, we
            are always happy to help.</p>
            <a className="text-white no-underline hover:underline" href="mailto:info@landl-build.com"><p className="mb-3 flex items-center"><img className="pr-3" src={Envelope} alt="Email" />info@landl-build.com</p></a>
            <a className="text-white no-underline hover:underline" href="tel:07926651960"><p className="flex items-center"><img className="pr-3" src={Phone} alt="Email" />079 266 519 60</p></a>
          </div>

          <div className="md:p-12 pb-5 md:w-full lg:w-3/6 xl:px-32">
            <Form />
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export const query = graphql`
query {
  allContentfulPortfolioWork(limit: 6, filter: {categories: {eq: "Project"}}, sort: {order: DESC, fields: updatedAt}) {
    nodes {
      slug
      title
      categories
      images {
        file {
          fileName
          url
        }
        fixed(width: 300, height: 300) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      createdAt
    }
  }
}
`