import React from "react"

const ReviewCard = ({ review }) => {
    const message = review.reviewSimple.internal.content
    console.log(review)

    return (
        <div>
            <div>{message}</div>
            <div className="font-bold">
                {review.name || `Client`}
            </div>
            {/* <div>Rating: {review.rating}</div> */}
        </div>
    )
}

export default ReviewCard