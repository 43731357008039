import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"

import ReviewCard from "./card"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ReviewSection = () => {
    const { allContentfulReviews } = useStaticQuery(
        graphql`
        query  {
            allContentfulReviews {
                nodes {
                name
                rating
                reviewSimple {
                    internal {
                    content
                    }
                }
              }
            }
        }
        `
    )
    const reviews = allContentfulReviews.nodes

    console.log(reviews)

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }
    return (
        <section className="bg-grayLight my-24 py-12 md:px-5 lg:px-16">
            <h1 className="relative orange-underline text-center mb-8">Customer Reviews</h1>
            <div className="mx-5 md:mx-40">
                <Slider {...settings}>
                    {reviews.map(review =>
                        <ReviewCard review={review} />
                    )}
                </Slider>
            </div>

        </section>
    )
}

export default ReviewSection