import React from 'react'
import BackgroundImage from 'gatsby-background-image'

import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"

const IndexHeader = () => {
  const data = useStaticQuery(graphql`
  query {
    livingroom: file(relativePath: { eq: "livingroom.jpg"}) {
      childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
          }
      }
    }
  }`)

  const imageData = data.livingroom.childImageSharp.fluid
  return (
      <BackgroundImage
        fluid={imageData}
        backgroundColor={`#232C3A`}
        className={"pb-12 z-10"}
        >
        <Header siteTitle="LandL-Build" />
          <h1 className="text-white text-center font-thin p-5 lg:p-24 xl:px-64">We build and maintain your dream home with passion and quality you deserve</h1>
          <div className="background-overlay bg-grayDarkest opacity-75" />
        </BackgroundImage>
  )
}

export default IndexHeader